<template>
  <div v-if="!loading && isUserLogged" class="rounded-md bg-slate-900 px-2 py-1 uppercase font-bold text-center animate-slide-down-fade-in-fast mb-1" @click="showInfoModal">
    <div v-if="ogPoints > 0">
      <span class="text-gold">$MWIN</span> OG Points:
      <span class="text-cyan">
        {{ $truncateNumber(ogPoints, 0, true) }}
      </span>
      <span v-if="false && ogPointsRank"> (#{{ $truncateNumber(ogPointsRank, 0, true) }})</span>
      <br>
    </div>
    <div>
      <span class="text-gold">$MWIN</span> Points:
      <span class="text-cyan">
        {{ $truncateNumber(points, 0, true) }}
      </span>
    </div>
    <span v-if="false && pointsRank"> (#{{ $truncateNumber(pointsRank, 0, true) }})</span>
    <div v-if="rafPoints > 0">
      <span class="text-gold">$MWIN</span> RAF Points:
      <span class="text-cyan">
        {{ $truncateNumber(rafPoints, 0, true) }}
      </span>
      <span v-if="false && rafPointsRank"> (#{{ $truncateNumber(rafPointsRank, 0, true) }})</span>
      <br>
    </div>
  </div>
</template>
<script setup>
import { useIntervalFn } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/auth';
import { useUiStore } from '@/store/ui';

const { $api, $rollbar, } = useNuxtApp();

const authStore = useAuthStore();
const { isUserLogged, } = storeToRefs(authStore);

const uiStore = useUiStore();
const { openInfoCmsModal, } = uiStore;

const loading = ref(true);
const ogPoints = ref(0);
const ogPointsRank = ref(null);
const points = ref(0);
const pointsRank = ref(null);
const rafPoints = ref(0);
const rafPointsRank = ref(null);

const { pause, resume, } = useIntervalFn(fetchOgPoints, 10000, false);

async function fetchOgPoints() {
  if (!isUserLogged.value) { return; }
  try {
    const response = await $api('statistics/ogpoints', {
      method: 'GET',
    });

    const detail = response.detail;
    rafPoints.value = 0;

    if (detail.og) {
      ogPoints.value = detail.og.totalPoints;
      ogPointsRank.value = detail.og.totalPointsRank;
    }

    if (detail.new) {
      points.value = detail.new.totalPoints;
      pointsRank.value = detail.new.totalPointsRank;
    }

    if (detail.raf) {
      rafPoints.value = detail.raf.totalPoints;
      rafPointsRank.value = detail.raf.totalPointsRank;
    }
  } catch (err) {
    $rollbar.error('fetchOgPoints method failed', err);
  } finally {
    loading.value = false;
  }
}

function showInfoModal() {
  openInfoCmsModal({ modalId: 'mwinpts', });
}

watch(isUserLogged, (newVal) => {
  if (newVal) {
    fetchOgPoints(); // Initial fetch
    resume(); // Start or resume the interval
  } else {
    points.value = 0;
    pointsRank.value = null;
    ogPoints.value = 0;
    ogPointsRank.value = null;
    pause(); // Pause the interval when logged out
  }
}, { immediate: true, });
</script>
